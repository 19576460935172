<template>
<v-row class="fill-height">
    <v-col>
      <v-btn
        elevation="2"
        class="d-block d-md-none"
        @click="drawer = !drawer"
        icon
        fab
        :bottom="true"
        :right="true"
        small
        fixed
      ><v-icon>mdi-menu</v-icon></v-btn>
      <v-navigation-drawer
        v-model="drawer"
        app
      >
        <v-list>
          <v-list-item>
            <v-img src="/img/icons/favicon-32x32.png" max-width="32" max-height="32" contain></v-img>
            <v-spacer></v-spacer>
            <v-btn fab icon @click="drawer = !drawer"><v-icon>mdi-close</v-icon></v-btn>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-btn fab icon small @click="prev">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab icon small @click="next">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-list-item-title v-if="$refs.calendar" class="text-right">
              {{ $refs.calendar.title }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="setToday">
            <v-list-item-content>
              <v-list-item-title>Today</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-select 
              :items="types"
              v-model="type"
              label="Type"
              class="ml-4 types-select"
              dense
              flat
            ></v-select>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-select 
              :items="groups"
              v-model="selected_groups"
              label="Filter groups"
              class="ml-4 groups-select"
              dense
              flat
              multiple
            ></v-select>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-sheet height="64" class="d-none d-md-block">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" @click="setToday" tile>Today</v-btn>
          <v-btn fab icon small @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab icon small @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select 
            :items="types"
            v-model="type"
            label="Type"
            class="ml-4 types-select"
            dense
            flat
          ></v-select>
          <v-select 
            :items="groups"
            v-model="selected_groups"
            class="ml-4 groups-select"
            label="Filter groups"
            dense
            flat
            multiple
          ></v-select>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="calc(100vh - 140px)">
        <v-calendar
          v-touch="{
            down: () => refreshPage()
          }"
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          first-time="09:00"
          interval-count="12"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          :position-x="offset"
          :position-y="offset"
          :nudge-width="width"
          absolute
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <router-link :to="{name: 'event', params: {id: selectedEvent.uid}}">&nbsp;<v-icon color="terteriary">mdi-link</v-icon></router-link>
              <v-spacer></v-spacer>
              <v-btn 
                icon
                @click="selectedOpen = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div v-if="event_past">
                <img :src="selectedEvent.featured_image_url" class="event-image"/>
                <div v-html="selectedEvent.description" class="event-description"></div>
              </div>
              <div>
                <span :id="selectedEvent.embed.id" style="width: 100%" v-mutate.child.sub="onMutate" ref="embed"></span>
              </div> 
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>


<script>
  import axios from "axios"
  import Vue from "vue"
  import _ from "lodash"
  import AddToCalendar from './AddToCalendar'
  
  export default {
    name: "EventCalendar",
    data: () => ({
      focus: '',
      drawer: false,
      event_past: true,
      offset: 0,
      width: 0,
      type: 'month',
      types: [
        {text: "Month", value: "month"},
        {text: "Week", value: "week"},
        {text: "Month", value: "day"},
        {text: "4 Days", value: "4day"}
      ],
      selectedEvent: {
        description: ""
        , embed: {
          id: ""
        }
      },
      selectedElement: null,
      selectedOpen: false,
      selected_groups: [],
      events: [],
      groups: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Political Education', 'Steering Committee', 'General Meeting', 'Labor Meeting'],
    }),
    mounted () {
      setTimeout(() => {
        this.fetchEvents();
      }, 300000);
      this.fetchEvents()
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': 
          this.offset = 10
          this.width = this.$vuetify.breakpoint.width
          break
        case 'sm': 
          this.offset = 30
          this.width = this.$vuetify.breakpoint.width
          break
        case 'md': 
          this.offset = 60
          break
        case 'lg':
          this.offset = 110
          break
        case 'xl': 
          this.offset = 220
          break
      }
    },
    methods: {
      copyEventLink () {
        // const test = ""
        // const link = RouterLink.get("event")
        // navigator.clipboard.writeText(copyText.value);
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.drawer = false;
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      onMutate (mutations, observer) {
        var addtocalendar_el = document.getElementById("add-to-calendar")
        if (addtocalendar_el) {
          observer.disconnect()
          var ComponentClass = Vue.extend(AddToCalendar)
          new ComponentClass({
            propsData: {selectedEvent: this.selectedEvent}
          }).$mount("#add-to-calendar")
          this.observeEmbed()
        }
      },
      observeEmbed () {
        if (this.$refs.embed) {
          const observer = new MutationObserver(this.onMutate)
          observer.observe(this.$refs.embed, {childList: true, subtree: true, attributes:false})
        }
      },
      refreshPage () {
        window.location.reload(true)
      },
      showEvent ({ nativeEvent, event }) {
        if (this.selectedEvent && this.selectedEvent.embed.id) {
          document.getElementById(this.selectedEvent.embed.id).innerHTML = ""
          this.$unloadScript(this.selectedEvent.embed.src)
        }
        const open = () => {
          this.selectedEvent = event
          this.event_past = new Date(this.selectedEvent.start) < new Date()
          if (this.event_past === false) {
            this.$loadScript(this.selectedEvent.embed.src)
          }
          if (nativeEvent) this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        if (nativeEvent) nativeEvent.stopPropagation()
      },
      fetchEvents (groups=null) {
        const events = [];
        axios.get("https://marvinroman.github.io/action-network-fetch/events.json")
          .then((response) => {
            response.data.forEach((event) => {
              if (groups == null || groups.indexOf(event.group) > -1) {
                events.push({
                  start: event.start
                  , end: event.end
                  , group: event.group
                  , link: event.link
                  , name: event.name
                  , timed: event.timed
                  , color: event.color
                  , description: event.description
                  , featured_image_url: event.featured_image_url
                  , embed: event.embed
                  , instructions: event.instructions
                  , location: event.location
                  , uid: event.uid.length > 0 ? event.uid.shift() : ""
                })
              }
            })

            if ({}.hasOwnProperty.call(this.$route.params, "id")) {
              const event_index = events.findIndex(event => {
                return this.$route.params.id == event.uid
              })
              if (event_index >= 0) {
                this.showEvent({nativeEvent: "", event: events[event_index]})
              }
            }
            if (groups === null) {
              this.groups = _.uniq(_.map(events, "group"))
            }
          });
          
          this.events = events;
      }
    },
    watch: {
      selected_groups: function(groups) {
        this.fetchEvents(groups)
      }
    }
  }
</script>


<style scoped>
.event-image {
  max-width: 100%;
  max-height: 400px;
}
.event-description {
  max-width: 100%;
}
.v-text-field.v-input--dense.groups-select,
.v-text-field.v-input--dense.types-select {
  margin-top: 1.5rem;
  max-width: 220px;
}
</style>s