<template>
  <div id="add-to-calendar" class="add-to-calendar-links js-add-to-calendar-links centertext mt20">
    <p class="nomb">Add this event to your calendar:</p>
    <p class="nomb">
      <a v-for="(link) in links" :key="link.name" :download="link.type === 'ics' ? 'event.ics' : false" :href="getHref(link.type)" target="_blank">{{link.name}}</a>
    </p>
  </div>
</template>

<script>
  import moment from 'moment';
  import MD5 from "crypto-js/md5";
  
  export default {
    name: "add-to-calendar",
    props: [ "selectedEvent" ],
    data: () => ({
      links: [
        {icon: "mdi-apple", name: "iCalendar", type: "ics"}
        , {icon: "mdi-google", name: "Google", type: "google"}
        , {icon: "mdi-microsoft-outlook", name: "Outlook", type: "ics"}
        , {icon: "mdi-microsoft-outlook", name: "Outlook Web", type: "outlook"}
        , {icon: "mdi-yahoo", name: "Yahoo", type: "yahoo"}
      ]
    }),
    methods: {
      getHref(type) {
        let location = {}.hasOwnProperty.call(this.selectedEvent.location, "address_lines") ? 
          `${this.selectedEvent.location.address_lines.join(", ")}, ${this.selectedEvent.location.locality} ${this.selectedEvent.location.region} ${this.selectedEvent.location.postal_code}` : ""
        const zoom_link_matches = this.selectedEvent.instructions.match(/(https:\/\/us\d+web.zoom.us\/j\/\d+)/ig)
        if (!location && zoom_link_matches) 
          location = zoom_link_matches.shift()
        if (type === "ics") {
          const description = `DESCRIPTION;ALTREP="data:text/html,${encodeURI(this.selectedEvent.instructions)}":${this.selectedEvent.instructions.replaceAll(/<\/\w+>/ig, "\\n").replaceAll(/<\w+>/ig, "")}`
          const datacontent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${this.selectedEvent.name}
URL:${this.selectedEvent.link}
LOCATION:${location}
UID:${this.selectedEvent.uid.length > 0 ? this.selectedEvent.shift : MD5(JSON.stringify(this.selectedEvent)).toString()}
DTSTART;TZID=America/Los_Angeles:${moment(this.selectedEvent.start).format("YYYYMMDDTHHmmss")}
DTEND;TZID=America/Los_Angeles:${moment(this.selectedEvent.end).format("YYYYMMDDTHHmmss")}
${description.replaceAll(/(.{75})/ig, "$1\n ")}
END:VEVENT
END:VCALENDAR`
          return `data:text/calendar;charset=utf8,${encodeURIComponent(datacontent)}`
        } else if (type === "google") {
          const google_url = new URL("https://www.google.com/calendar/render")
          google_url.searchParams.set("action", "TEMPLATE")
          google_url.searchParams.set("text", this.selectedEvent.name)
          google_url.searchParams.set("dates", `${moment(this.selectedEvent.start).format("YYYYMMDDTHHmmss")}/${moment(this.selectedEvent.end).format("YYYYMMDDTHHmmss")}`)
          google_url.searchParams.set("location", location)
          google_url.searchParams.set("details", this.selectedEvent.instructions)
          return google_url
        } else if (type === "outlook") {
          const outlook_url = new URL("https://outlook.live.com/calendar/0/deeplink/compose")
          outlook_url.searchParams.set("path", "/calendar/action/compose")
          outlook_url.searchParams.set("rru", "addevent")
          outlook_url.searchParams.set("subject", this.selectedEvent.name)
          outlook_url.searchParams.set("startdt", moment(this.selectedEvent.start).format("YYYY-MM-DDTHH:mm:ss"))
          outlook_url.searchParams.set("enddt", moment(this.selectedEvent.end).format("YYYY-MM-DDTHH:mm:ss"))
          outlook_url.searchParams.set("body", this.selectedEvent.instructions)
          outlook_url.searchParams.set("location", location)
          return outlook_url
        } else if (type === "yahoo") {
          const yahoo_url = new URL("https://calendar.yahoo.com/")
          yahoo_url.searchParams.set("v", "60")
          yahoo_url.searchParams.set("view", "d")
          yahoo_url.searchParams.set("type", "20")
          yahoo_url.searchParams.set("title", this.selectedEvent.name)
          yahoo_url.searchParams.set("st", moment(this.selectedEvent.start).format("YYYYYMMDDTHHmmss"))
          yahoo_url.searchParams.set("et", moment(this.selectedEvent.end).format("YYYYYMMDDTHHmmss"))
          yahoo_url.searchParams.set("desc", this.selectedEvent.instructions)
          yahoo_url.searchParams.set("in_loc", location)
          return yahoo_url
        }
      }
    }
  }
</script>
