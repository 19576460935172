<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <router-link :to="{name: 'home'}">
          <v-img
            alt="Inland Empire DSA Logo"
            class="shrink mr-2"
            contain
            src="./assets/iedsa_logo.svg"
            transition="scale-transition"
            width="40"
          />
        </router-link>
        <v-toolbar-title>Inland Empire DSA Events Calendar</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { RouterView } from 'vue-router';

export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: { RouterView }
};
</script>
